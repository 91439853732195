<template>
  <div>
    <h3>
      {{ $t("tag.fields.name") }}:
      <span class="subtitle-1">{{ data.tag.name }}</span>
    </h3>

    <h3>
      {{ $t("tag.fields.deveui") }}:
      <span class="subtitle-1">{{ data.tag.deveui }}</span>
    </h3>
    <h3>
      {{ $t("tag.fields.profile") }}:
      <span class="subtitle-1">{{ data.tag.profile }}</span>
    </h3>

    <v-divider />

    <h3 v-for="(v, i) in data.data" v-bind:key="'so-content-leaflet-' + i">
      {{ v.key }}: {{ v.value }}
    </h3>

    <v-img
      v-if="data.tag.installationImage && SO_API_BASE_URL != undefined"
      :src="SO_API_BASE_URL + '/api/image/' + data.tag.installationImage"
    ></v-img>

    <v-btn
      v-if="mode == DashboardMode.EDIT"
      color="error"
      text
      @click="$emit('remove-marker', data)"
    >
      {{ $t("common.delete") }}
    </v-btn>
  </div>
</template>

<script>
import Enum from "@/_helpers/Enum";
import { mapActions, mapState } from "vuex";

export default {
  name: "PopupContent",
  props: ["data", "mode"],

  computed: {
    ...mapState("configuration", ["SO_API_BASE_URL"]),

    DashboardMode() {
      return Enum.DashboardMode;
    },
  },

  methods: {
    ...mapActions("configuration", ["getBaseUrl"]),
  },

  async created() {
    await this.getBaseUrl();
  },
};
</script>
